// src/redux/store.ts

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { applyMiddleware } from 'redux';
import {
  DeviceEventMediaLinkState,
  DeviceEventMediaSliceName,
  DeviceEventMediaSliceReducer,
} from './deviceEventMediaSlice';
import { DeviceEventsSliceName, DeviceEventsSliceReducer, DeviceEventsState } from './deviceEventSlice';
export interface AppState {
  [DeviceEventsSliceName]: DeviceEventsState;
  [DeviceEventMediaSliceName]: DeviceEventMediaLinkState;
}
export const store = configureStore<AppState>({
  reducer: combineReducers({
    [DeviceEventsSliceName]: DeviceEventsSliceReducer,
    [DeviceEventMediaSliceName]: DeviceEventMediaSliceReducer,
  }),
});
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
