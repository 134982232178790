export enum DeviceEventType {
  FRONTAL_COLLISION = 'frontal-collision',
  TAILGATING = 'tailgating',
  LANE_DEPARTURE = 'lane-departure',
  DISTRACTED = 'distracted',
  CELLPHONE = 'cell-phone',
  FATIGUE = 'fatigue',
}
export interface DeviceEventComboBoxEntry {
  deviceEventType: DeviceEventType;
  label: string;
}
export const DEVICE_EVENT_COMBOBOX_ENTRIES: Record<DeviceEventType, DeviceEventComboBoxEntry> = {
  'frontal-collision': {
    deviceEventType: DeviceEventType.FRONTAL_COLLISION,
    label: DeviceEventType.FRONTAL_COLLISION,
  },
  tailgating: {
    deviceEventType: DeviceEventType.TAILGATING,
    label: DeviceEventType.TAILGATING,
  },
  'lane-departure': {
    deviceEventType: DeviceEventType.LANE_DEPARTURE,
    label: DeviceEventType.LANE_DEPARTURE,
  },
  distracted: {
    deviceEventType: DeviceEventType.DISTRACTED,
    label: DeviceEventType.DISTRACTED,
  },
  'cell-phone': {
    deviceEventType: DeviceEventType.CELLPHONE,
    label: DeviceEventType.CELLPHONE,
  },
  fatigue: {
    deviceEventType: DeviceEventType.FATIGUE,
    label: DeviceEventType.FATIGUE,
  },
};
