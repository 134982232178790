export enum DeviceEventMediaType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export interface DeviceEventMedia {
  mediaType: DeviceEventMediaType;
  fileName: string;
  s3Url: string;
}
