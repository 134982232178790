import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DateTime, FixedOffsetZone, Zone } from 'luxon';
import { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DeviceEvent } from '../../model/device-events/device-event';
import { DEVICE_EVENT_COMBOBOX_ENTRIES } from '../../model/device-events/device-event-type';
import { DeviceEventSliceActions, DeviceEventsSelectors } from '../../redux/deviceEventSlice';
import { useAppDispatch } from '../../redux/store';
import { DeviceEventReviewComponent } from '../DeviceEventReviewComponent/device-event-review-component';
export const DeviceEventTable: FunctionComponent<{}> = () => {
  const dispatch = useAppDispatch();
  const eventData = useSelector(DeviceEventsSelectors.selectDeviceEvents);
  const deviceEventFilter = useSelector(DeviceEventsSelectors.selectFilterState);
  const lastEvaluatedKey = useSelector(DeviceEventsSelectors.selectLastEvaluatedKey);
  const loading = useSelector(DeviceEventsSelectors.selectLoading);
  const page = useSelector(DeviceEventsSelectors.selectPage);
  const pageSize = useSelector(DeviceEventsSelectors.selectPageSize);
  const rowCount = useSelector(DeviceEventsSelectors.selectRowCount);

  const onPageSizeChange = (newSize: number) => {
    const oldSize = pageSize;

    if (newSize > oldSize) {
      dispatch(
        DeviceEventSliceActions.getPaginatedDeviceEventsAppendThunk({
          ...deviceEventFilter,
          pageSize: newSize,
        })
      );
    }
    dispatch(DeviceEventSliceActions.setPageSize(newSize));
  };
  const getRowCount = () => {
    if (rowCount % pageSize === 0 && eventData.length) {
      return Number.MAX_SAFE_INTEGER;
    } else {
      return rowCount;
    }
  };
  const getRowId = (row: DeviceEvent) => row.eventId;
  const onPageChange = newPage => {
    if (newPage > page) {
      dispatch(
        DeviceEventSliceActions.getPaginatedDeviceEventsAppendThunk({
          ...deviceEventFilter,
        })
      );
    }
    dispatch(DeviceEventSliceActions.setPage(newPage));
  };
  const DEFAULT_COL_DEF: Partial<GridColDef> = {
    sortable: false,
    flex: 1,
    filterable: false,
  };
  const COLUMNS: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      ...DEFAULT_COL_DEF,
    },
    {
      field: 'eventId',
      headerName: 'Event Id',
      ...DEFAULT_COL_DEF,
    },
    {
      field: 'deviceId',
      headerName: 'Device Id',
      ...DEFAULT_COL_DEF,
    },
    {
      field: 'type',
      headerName: 'Event Type',
      renderCell: params => <Typography>{DEVICE_EVENT_COMBOBOX_ENTRIES[params.value]?.label}</Typography>,
      ...DEFAULT_COL_DEF,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      renderCell: params => {
        /*
        So basically the problem here are timezones. If you initialize an event from millis, it will initialize in the timezone that
        was valid while the event occurred. This means that you can have a situation where there are two different timezones used in the row: One
        that was valid during daylight savings, and one that was valid otherwise.

        To get around this we set the offset to the exact same offset of a current valid timestamp. However, this changes the abbreviation
        from something like EST -> GMT+5. To get around that we manually append the friendly offset name from the current datetime. 
        */
        const currentDT = DateTime.now();
        const timestampDT = DateTime.fromMillis(params.value).setZone(FixedOffsetZone.instance(currentDT.offset));
        const formattedTimestamp = `${timestampDT.toFormat('f')} ${currentDT.offsetNameShort}`;
        return <Typography>{formattedTimestamp}</Typography>;
      },
      ...DEFAULT_COL_DEF,
    },
    {
      field: '',
      headerName: 'Review',
      renderCell: params => <DeviceEventReviewComponent event={params.row}></DeviceEventReviewComponent>,
      type: 'actions',
      ...DEFAULT_COL_DEF,
      flex: 0,
      width: 100,
    },
  ];
  return (
    <Box
      sx={{
        height: '85vh',
        '.MuiTablePagination-displayedRows': {
          display: 'none',
        },
      }}>
      <DataGrid
        getRowId={getRowId}
        loading={loading}
        rows={eventData}
        columns={COLUMNS}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        page={page}
        pageSize={pageSize}
        rowCount={getRowCount()}></DataGrid>
    </Box>
  );
};
