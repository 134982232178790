import { DateTime } from 'luxon';
import { DeviceEvent, DeviceEventKey } from './device-event';
import { DeviceEventType, DEVICE_EVENT_COMBOBOX_ENTRIES } from './device-event-type';

export const getAbsoluteMinDt = () => DateTime.now().startOf('day').minus({ days: 60 });

export interface DeviceEventFilter {
  eventBefore: number | null;
  eventAfter: number | null;
  types: DeviceEventType[];
  titleContains: string;
  deviceId: string;
}

export interface PaginatedDeviceEventFilter extends DeviceEventFilter {
  pageSize: number;
  lastEvaluatedKey: DeviceEventKey | null;
}

export interface ChangedSizePaginatedDeviceEventFilter extends PaginatedDeviceEventFilter {
  oldSize: number;
}

export const DEFAULT_EVENT_FILTERS: DeviceEventFilter = {
  eventBefore: null,
  eventAfter: getAbsoluteMinDt().toMillis(),
  types: Object.values(DeviceEventType),
  titleContains: '',
  deviceId: '',
};

export const DEFAULT_PAGINATED_DEVICE_EVENT_FILTER: PaginatedDeviceEventFilter = {
  ...DEFAULT_EVENT_FILTERS,
  pageSize: 50,
  lastEvaluatedKey: null,
};
