import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime, Duration } from 'luxon';
import { Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { DeviceEventType, DEVICE_EVENT_COMBOBOX_ENTRIES } from '../../model/device-events/device-event-type';
import { DEFAULT_EVENT_FILTERS, DeviceEventFilter } from '../../model/device-events/device-event-filters';
export interface PaginatedFilterPanelProps {
  onSubmit: (values: DeviceEventFilter) => void;
}
export const PaginatedFilterPanel: FunctionComponent<PaginatedFilterPanelProps> = ({ onSubmit }) => {
  const getValidTime = (dateTime: number | null) => {
    if (!dateTime) {
      return null;
    } else {
      return DateTime.fromMillis(dateTime);
    }
  };

  const getValidDateTimeFormat = () => DateTime.parseFormatForOpts(DateTime.DATETIME_FULL) as string;
  const getComboboxOptions = (types: DeviceEventType[]) => types.map(type => DEVICE_EVENT_COMBOBOX_ENTRIES[type]);
  return (
    <Box>
      <Formik onSubmit={onSubmit} initialValues={DEFAULT_EVENT_FILTERS}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
        }) => (
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={4}>
              <TextField
                id='titleContains'
                sx={{
                  width: '100%',
                }}
                value={values.titleContains}
                onChange={handleChange}
                label='Title Contains'></TextField>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                filterSelectedOptions
                value={getComboboxOptions(values.types)}
                onChange={(e, value) => {
                  setFieldValue(
                    'types',
                    value.map(entry => entry.deviceEventType)
                  );
                }}
                multiple
                options={Object.values(DEVICE_EVENT_COMBOBOX_ENTRIES)}
                renderInput={params => (
                  <TextField {...params} label='Device Event Types' placeholder='Event Types'></TextField>
                )}></Autocomplete>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                  disableMaskedInput
                  value={getValidTime(values.eventAfter)}
                  minDateTime={getValidTime(values.eventAfter)}
                  maxDateTime={getValidTime(values.eventBefore)}
                  onChange={newValue => setFieldValue('eventAfter', newValue?.toMillis())}
                  label='From'
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                  disableMaskedInput
                  minDateTime={getValidTime(values.eventAfter)}
                  value={getValidTime(values.eventBefore)}
                  onChange={newValue => setFieldValue('eventBefore', newValue?.toMillis())}
                  label='To'
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id='deviceId'
                sx={{
                  width: '100%',
                }}
                value={values.deviceId}
                onChange={handleChange}
                label='Device Id'></TextField>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid
              item
              justifySelf='end'
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
              }}>
              <Button
                variant='outlined'
                onClick={() => {
                  resetForm();
                  handleSubmit();
                }}>
                Clear
              </Button>
              <Button onClick={() => handleSubmit()} variant='contained' type='submit'>
                Apply
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
};
