import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getEventsPaginated } from '../API/GetEventsPaginated';
import { DeviceEvent, DeviceEventKey } from '../model/device-events/device-event';
import {
  ChangedSizePaginatedDeviceEventFilter,
  DEFAULT_PAGINATED_DEVICE_EVENT_FILTER,
  DeviceEventFilter,
  PaginatedDeviceEventFilter,
} from '../model/device-events/device-event-filters';
import { selectDeviceEventsState } from './selector.root';

export interface DeviceEventsState {
  filter: PaginatedDeviceEventFilter;
  currentEvents: DeviceEvent[];
  rowCount: number;
  loading: boolean;
  failure: boolean;
  page: number;
}

const initialState: DeviceEventsState = {
  filter: DEFAULT_PAGINATED_DEVICE_EVENT_FILTER,
  currentEvents: [],
  rowCount: 0,
  loading: false,
  failure: false,
  page: 0,
};

const getPaginatedDeviceEventsThunk = createAsyncThunk(
  'device-events/getPaginatedDeviceEvents',
  async (filter: PaginatedDeviceEventFilter) => getEventsPaginated(filter)
);
const getPaginatedDeviceEventsAppendThunk = createAsyncThunk(
  'device-events/getPaginatedDeviceEventsAppend',
  async (filter: PaginatedDeviceEventFilter) => {
    return getEventsPaginated(filter);
  }
);
const deviceEventsSlice = createSlice({
  name: 'device-events',
  initialState,
  reducers: {
    clearFilter: state => {
      state = initialState;
    },
    updateFilter: (state, action: PayloadAction<DeviceEventFilter>) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.filter.pageSize = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPaginatedDeviceEventsThunk.pending, state => {
      state.loading = true;
    });
    builder.addCase(getPaginatedDeviceEventsThunk.rejected, state => {
      state.loading = false;
      state.failure = true;
    });
    builder.addCase(getPaginatedDeviceEventsThunk.fulfilled, (state, action: PayloadAction<DeviceEvent[]>) => {
      state.page = 0;
      state.failure = false;
      state.loading = false;
      if (action.payload.length) {
        state.filter.lastEvaluatedKey = action.payload[action.payload.length - 1];
      } else {
        state.filter.lastEvaluatedKey = null;
      }
      state.currentEvents = action.payload;
      state.rowCount = action.payload.length;
    });
    builder.addCase(getPaginatedDeviceEventsAppendThunk.pending, state => {
      state.loading = true;
    });
    builder.addCase(getPaginatedDeviceEventsAppendThunk.rejected, state => {
      state.failure = true;
      state.loading = false;
    });
    builder.addCase(getPaginatedDeviceEventsAppendThunk.fulfilled, (state, action: PayloadAction<DeviceEvent[]>) => {
      state.failure = false;
      state.loading = false;
      if (action.payload.length) {
        state.filter.lastEvaluatedKey = action.payload[action.payload.length - 1];
      } else {
        state.filter.lastEvaluatedKey = null;
      }
      state.currentEvents = state.currentEvents.concat(action.payload);
      state.rowCount += action.payload.length;
    });
  },
});

export const DeviceEventSliceActions = {
  ...deviceEventsSlice.actions,
  getPaginatedDeviceEventsAppendThunk,
  getPaginatedDeviceEventsThunk,
};

const selectFilterState = createSelector(selectDeviceEventsState, state => state.filter);
const selectPageSize = createSelector(selectFilterState, filter => filter.pageSize);
const selectRowCount = createSelector(selectDeviceEventsState, state => state.rowCount);
const selectDeviceEvents = createSelector(selectDeviceEventsState, state => state.currentEvents);
const selectLastEvaluatedKey = createSelector(selectFilterState, state => state.lastEvaluatedKey);
const selectLoading = createSelector(selectDeviceEventsState, state => state.loading);
const selectFailure = createSelector(selectDeviceEventsState, state => state.failure);
const selectPage = createSelector(selectDeviceEventsState, state => state.page);

export const DeviceEventsSelectors = {
  selectFilterState,
  selectPageSize,
  selectRowCount,
  selectDeviceEvents,
  selectLastEvaluatedKey,
  selectLoading,
  selectFailure,
  selectPage,
};

export const DeviceEventsSliceReducer = deviceEventsSlice.reducer;

export const DeviceEventsSliceName = deviceEventsSlice.name;
