import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DeviceEvent } from '../../model/device-events/device-event';
import {
  DeviceEventReviewAccuracyType,
  DeviceEventReviewData,
  DeviceEventReviewTimeOfDay,
  DeviceEventReviewWeather,
  DEVICE_EVENT_REVIEW_ACCURACY_OPTIONS,
  DEVICE_EVENT_REVIEW_TIME_OF_DAY_OPTIONS,
  DEVICE_EVENT_REVIEW_WEATHER_OPTIONS,
  getDeviceEventReviewOption,
  TypedComboBoxOption,
} from '../../model/reviewed-device-event/reviewed-device-event';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { submitDeviceEventReview } from '../../API/SubmitDeviceEventReivew';
import { Formik } from 'formik';
import { useAppDispatch } from '../../redux/store';
import { DeviceEventMediaSelectors, DeviceEventMediaSliceActions } from '../../redux/deviceEventMediaSlice';
import { useSelector } from 'react-redux';
import { DeviceEventMedia } from '../../model/device-events/device-event-media';
import { DeviceEventMediaViewerComponent } from '../DeviceEventMediaViewer/device-event-media-viewer';
export interface DeviceEventReviewComponentParams {
  event: DeviceEvent;
}
const DEFAULT_EVENT_REIVEW_FILTERS: DeviceEventReviewData = {
  accuracy: DeviceEventReviewAccuracyType.ACCURATE,
  weather: DeviceEventReviewWeather.CLEAR,
  timeOfDay: DeviceEventReviewTimeOfDay.DAY,
};
export const DeviceEventReviewComponent: FunctionComponent<DeviceEventReviewComponentParams> = ({ event }) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50rem',
  };
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [selectedMedia, setSelectedMedia] = useState<DeviceEventMedia | null>(null);
  const mediaLoadState = useSelector(DeviceEventMediaSelectors.selectLoadState);
  const mediaLinks = useSelector(DeviceEventMediaSelectors.selectMediaLinks);
  const renderLinks = () => {
    if (mediaLoadState === 'loading') {
      return <CircularProgress></CircularProgress>;
    }
    if (mediaLoadState === 'failure') {
      return <Typography variant='body2'>Failed to Load Data</Typography>;
    }
    if (mediaLoadState === 'loaded') {
      if (!mediaLinks.length) {
        return <Typography variant='body2'>No Media Links Found. Event is not reviewable</Typography>;
      } else {
        return (
          <>
            <Autocomplete
              value={selectedMedia}
              options={mediaLinks}
              getOptionLabel={opt => opt.fileName}
              renderInput={params => (
                <TextField label='Selected Media File' placeholder='Media File' {...params}></TextField>
              )}
              onChange={(event, value) => setSelectedMedia(value)}></Autocomplete>
            <DeviceEventMediaViewerComponent deviceMedia={selectedMedia}></DeviceEventMediaViewerComponent>
          </>
        );
      }
    }
  };

  const handleClose = () => {
    dispatch(DeviceEventMediaSliceActions.resetMedia());
    setOpen(false);
  };

  const onSubmit = async (submission: DeviceEventReviewData) => {
    try {
      await submitDeviceEventReview({
        review: submission,
        event,
      });
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const openCloseModal = () => {
    if (open) {
      handleClose();
    } else {
      dispatch(DeviceEventMediaSliceActions.getDeviceEventLinksThunk(event));
      setOpen(true);
    }
  };

  return (
    <Box>
      <IconButton onClick={() => openCloseModal()}>
        <RateReviewIcon></RateReviewIcon>
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <div>
          {' '}
          <Formik onSubmit={onSubmit} initialValues={DEFAULT_EVENT_REIVEW_FILTERS}>
            {({ values, handleSubmit, setFieldValue }) => (
              <Card sx={style}>
                <CardHeader title={`${event.title} (${event.eventId})`}></CardHeader>
                <CardContent>
                  <Grid container rowSpacing={2} justifyContent='space-around'>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Event Files</Typography>
                      {renderLinks()}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Event Tags</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        value={getDeviceEventReviewOption<DeviceEventReviewAccuracyType>(
                          values.accuracy,
                          DEVICE_EVENT_REVIEW_ACCURACY_OPTIONS
                        )}
                        autoComplete={false}
                        disableClearable={true}
                        onChange={(e, value) => {
                          setFieldValue('accuracy', value?.type);
                        }}
                        options={Object.values(DEVICE_EVENT_REVIEW_ACCURACY_OPTIONS)}
                        renderInput={params => <TextField {...params} label='Accuracy' />}></Autocomplete>
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        value={getDeviceEventReviewOption<DeviceEventReviewWeather>(
                          values.weather,
                          DEVICE_EVENT_REVIEW_WEATHER_OPTIONS
                        )}
                        autoComplete={false}
                        disableClearable={true}
                        onChange={(e, value) => {
                          setFieldValue('weather', value.type);
                        }}
                        options={Object.values(DEVICE_EVENT_REVIEW_WEATHER_OPTIONS)}
                        renderInput={params => <TextField {...params} label='Weather' />}></Autocomplete>
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        autoComplete={false}
                        disableClearable={true}
                        options={Object.values(DEVICE_EVENT_REVIEW_TIME_OF_DAY_OPTIONS)}
                        renderInput={params => <TextField {...params} label='Time of Day' />}
                        value={getDeviceEventReviewOption<DeviceEventReviewTimeOfDay>(
                          values.timeOfDay,
                          DEVICE_EVENT_REVIEW_TIME_OF_DAY_OPTIONS
                        )}
                        onChange={(e, value) => {
                          setFieldValue('timeOfDay', value.type);
                        }}></Autocomplete>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                  <Button size='small' onClick={() => handleClose()}>
                    Cancel
                  </Button>
                  <Button size='small' onClick={() => handleSubmit()}>
                    Submit
                  </Button>
                </CardActions>
              </Card>
            )}
          </Formik>
        </div>
      </Modal>
    </Box>
  );
};
