import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { DeviceEventMedia, DeviceEventMediaType } from '../../model/device-events/device-event-media';
export interface DeviceEventMediaViewerParams {
  deviceMedia: DeviceEventMedia | null;
}

export const DeviceEventMediaViewerComponent: FunctionComponent<DeviceEventMediaViewerParams> = ({ deviceMedia }) => {
  const renderMedia = () => {
    if (!deviceMedia) {
      return <Typography variant='body2'>No Media Selected</Typography>;
    } else if (deviceMedia.mediaType === DeviceEventMediaType.IMAGE) {
      return <img height={270} width={480} src={deviceMedia.s3Url}></img>;
    } else if (deviceMedia.mediaType === DeviceEventMediaType.VIDEO) {
      return <video height={270} width={480} src={deviceMedia.s3Url} autoPlay controls loop></video>;
    }
    return <Typography>File Type Not Supported</Typography>;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingY: '1vh',
      }}>
      {renderMedia()}
    </Box>
  );
};
