import { CardHeader, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { PaginatedFilterPanel } from '../components/PaginatedFilterPanel/paginated-filter-panel';
import { DeviceEventFilter } from '../model/device-events/device-event-filters';
import { DEVICE_EVENT_COMBOBOX_ENTRIES } from '../model/device-events/device-event-type';
import { DeviceEventSliceActions, DeviceEventsSelectors } from '../redux/deviceEventSlice';
import { useAppDispatch } from '../redux/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DeviceEventTable } from '../components/DeviceEventTable/device-event-table';
export const DeviceFilteredView: FunctionComponent<{}> = () => {
  const dispatch = useAppDispatch();
  const pageSize = useSelector(DeviceEventsSelectors.selectPageSize);
  const deviceEventFilter = useSelector(DeviceEventsSelectors.selectFilterState);
  useEffect(() => {
    dispatch(DeviceEventSliceActions.getPaginatedDeviceEventsThunk(deviceEventFilter));
  }, []);
  const dataLoadedFailure = useSelector(DeviceEventsSelectors.selectFailure);
  const onFilterUpdate = async (filter: DeviceEventFilter) => {
    dispatch(DeviceEventSliceActions.updateFilter(filter));
    dispatch(
      DeviceEventSliceActions.getPaginatedDeviceEventsThunk({
        ...filter,
        pageSize,
        lastEvaluatedKey: null,
      })
    );
  };

  const renderTable = () => {
    if (dataLoadedFailure) {
      return (
        <Grid container>
          <Grid item xs={12} justifyContent='center'>
            <Typography>Failed to Load Data</Typography>
          </Grid>
        </Grid>
      );
    }
    return <DeviceEventTable></DeviceEventTable>;
  };
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon></ExpandMoreIcon>}>
          <Typography variant='h6'>Event Filter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PaginatedFilterPanel onSubmit={onFilterUpdate}></PaginatedFilterPanel>
        </AccordionDetails>
      </Accordion>
      {renderTable()}
    </>
  );
};
