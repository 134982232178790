import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EventsScreen from './EventsScreen';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { ClipViewForm, MainContentForClipViewForm } from './components';
import { DeviceFilteredView } from './pages/device-filtered-view';
import Box from '@mui/material/Box';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { Amplify } from 'aws-amplify';
import { region, userPoolId, userPoolWebClientId, endpoint } from './API/properties';
import { Provider } from 'react-redux';
import { store } from './redux/store';
export const AppRouter = () => {
  Amplify.configure({
    Auth: {
      region,
      userPoolId,
      userPoolWebClientId,
    },
    API: {
      endpoints: [
        {
          name: 'events',
          endpoint: `${endpoint}`,
          custom_header: async () => {
            const jwt = Amplify.Auth.user.signInUserSession.idToken.jwtToken;
            return {
              Authorization: `Bearer ${jwt}`,
            };
          },
        },
      ],
    },
  });
  return (
    <Provider store={store}>
      <Authenticator>
        {({ signOut, user }) => (
          <Box
            sx={{
              height: '100vh',
              flexGrow: 1,
            }}>
            {/* Have to do all this hacky crap to make the datagrid work */}
            <AppBar
              position='static'
              sx={{
                height: '50px',
              }}>
              <Toolbar>
                <IconButton size='large' edge='start' sx={{ mr: 2 }} color='inherit'>
                  <MenuIcon></MenuIcon>
                </IconButton>
                <Typography variant='h6' sx={{ flexGrow: 1 }}>
                  AIT Review Tool
                </Typography>
                <IconButton onClick={signOut} size='large' color='inherit'>
                  <LogoutIcon></LogoutIcon>
                </IconButton>
              </Toolbar>
            </AppBar>
            <Router>
              <Routes>
                <Route index element={<DeviceFilteredView></DeviceFilteredView>}></Route>
              </Routes>
            </Router>
          </Box>
        )}
      </Authenticator>
    </Provider>
  );
};
