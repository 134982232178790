import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stat } from 'fs';
import { DeviceEventKey } from '../model/device-events/device-event';
import { DeviceEventMedia } from '../model/device-events/device-event-media';
import { selectDeviceEventMediaState } from './selector.root';
import { getDeviceEventLinks } from '../API/GetDeviceEventLinks';
export interface DeviceEventMediaLinkState {
  media: DeviceEventMedia[];
  loadState: 'unloaded' | 'loading' | 'failure' | 'loaded';
}

const initialState: DeviceEventMediaLinkState = {
  media: [],
  loadState: 'unloaded',
};

const getDeviceEventLinksThunk = createAsyncThunk('device-events/getDeviceEventLinks', async (key: DeviceEventKey) => {
  return getDeviceEventLinks(key);
});

const deviceEventMediaSlice = createSlice({
  name: 'device-event-media',
  initialState,
  reducers: {
    resetMedia: state => (state = initialState),
  },
  extraReducers: builder => {
    builder.addCase(getDeviceEventLinksThunk.pending, state => {
      state.loadState = 'loading';
    });
    builder.addCase(getDeviceEventLinksThunk.rejected, state => {
      state.loadState = 'failure';
    });
    builder.addCase(getDeviceEventLinksThunk.fulfilled, (state, action: PayloadAction<DeviceEventMedia[]>) => {
      state.loadState = 'loaded';
      state.media = action.payload;
    });
  },
});

const selectLoadState = createSelector(selectDeviceEventMediaState, state => state.loadState);
const selectMediaLinks = createSelector(selectDeviceEventMediaState, state => state.media);
export const DeviceEventMediaSliceReducer = deviceEventMediaSlice.reducer;
export const DeviceEventMediaSliceName = deviceEventMediaSlice.name;

export const DeviceEventMediaSelectors = {
  selectLoadState,
  selectMediaLinks,
};
export const DeviceEventMediaSliceActions = {
  ...deviceEventMediaSlice.actions,
  getDeviceEventLinksThunk,
};
