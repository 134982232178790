import { DeviceEvent } from '../device-events/device-event';
import { DeviceEventType } from '../device-events/device-event-type';

export enum DeviceEventReviewAccuracyType {
  ACCURATE = 'accurate',
  FALSE_POSITIVE = 'false-positive',
  FALSE_NEGATIVE = 'false-negative',
  TRAINING_EXAMPLE = 'training-example',
}
export enum DeviceEventReviewTimeOfDay {
  MORNING = 'morning',
  EVENING = 'evening',
  DAY = 'day',
  NIGHT = 'night',
}
export enum DeviceEventReviewWeather {
  CLEAR = 'clear',
  RAIN = 'rain',
  CLOUDY = 'cloudy',
  SNOW = 'snow',
  FOG = 'fog',
}

export interface DeviceEventReviewData {
  accuracy: DeviceEventReviewAccuracyType;
  timeOfDay: DeviceEventReviewTimeOfDay;
  weather: DeviceEventReviewWeather;
}

export interface DeviceEventReviewSubmission {
  event: DeviceEvent;
  review: DeviceEventReviewData;
}

export interface DeviceEventReviewEntry extends DeviceEventReviewData {
  eventId: string;
  deviceId: string;
}

export interface TypedComboBoxOption<T> {
  type: T;
  label: string;
}
export const DEVICE_EVENT_REVIEW_ACCURACY_OPTIONS: Record<
  DeviceEventReviewAccuracyType,
  TypedComboBoxOption<DeviceEventReviewAccuracyType>
> = {
  accurate: {
    type: DeviceEventReviewAccuracyType.ACCURATE,
    label: 'Accurate',
  },
  'false-negative': {
    type: DeviceEventReviewAccuracyType.FALSE_NEGATIVE,
    label: 'False Negative',
  },
  'false-positive': {
    type: DeviceEventReviewAccuracyType.FALSE_POSITIVE,
    label: 'False Positive',
  },
  'training-example': {
    type: DeviceEventReviewAccuracyType.TRAINING_EXAMPLE,
    label: 'Training Example',
  },
};
export const DEVICE_EVENT_REVIEW_TIME_OF_DAY_OPTIONS: Record<
  DeviceEventReviewTimeOfDay,
  TypedComboBoxOption<DeviceEventReviewTimeOfDay>
> = {
  day: {
    type: DeviceEventReviewTimeOfDay.DAY,
    label: 'Daytime',
  },
  night: {
    type: DeviceEventReviewTimeOfDay.NIGHT,
    label: 'Nighttime',
  },
  evening: {
    type: DeviceEventReviewTimeOfDay.EVENING,
    label: 'Evening',
  },
  morning: {
    type: DeviceEventReviewTimeOfDay.MORNING,
    label: 'Morning',
  },
};
export const DEVICE_EVENT_REVIEW_WEATHER_OPTIONS: Record<
  DeviceEventReviewWeather,
  TypedComboBoxOption<DeviceEventReviewWeather>
> = {
  clear: {
    type: DeviceEventReviewWeather.CLEAR,
    label: 'Clear',
  },
  cloudy: {
    type: DeviceEventReviewWeather.CLOUDY,
    label: 'Cloudy',
  },
  fog: {
    type: DeviceEventReviewWeather.FOG,
    label: 'Fog',
  },
  rain: {
    type: DeviceEventReviewWeather.RAIN,
    label: 'Rain',
  },
  snow: {
    type: DeviceEventReviewWeather.SNOW,
    label: 'Snow',
  },
};

export function getDeviceEventReviewOption<E extends string>(
  type: E,
  options: Record<E, TypedComboBoxOption<E>>
): TypedComboBoxOption<E> {
  return options[type];
}
